import '@/styles/app.scss';
import './modules/modal';
import './modules/navbar';
import { initHamburger } from './modules/hamburger';

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => console.log('HMR'));
}

initHamburger();
